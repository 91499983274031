import React from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import {REACT_APP_PDF_FONT_REGULAR, REACT_APP_PDF_FONT_BOLD, Q_MCQ, Q_YES_NO_WITH_TEXT, Q_YES_NO_WITH_MULTIPLE_TEXT, Q_CATEGORIES} from '../../../constants';
import pdfImage from './images/pdfImage.png';
import scoreImage1 from './images/score-Psychological.png';
import scoreImage2 from './images/score-Social.png';
import scoreImage3 from './images/score-Health.png';
import scoreImage4 from './images/score-Functional.png';
import scoreImage5 from './images/score-Medication.png';
import scoreImage6 from './images/score-overall.png';

// Registering of the font for this PDF would require pure TTF font - better be from gstatic source.
Font.register({
  family: 'Rubik',
  fonts: [
    {src: `${REACT_APP_PDF_FONT_REGULAR}`, fontStyle:'regular'},
    {src: `${REACT_APP_PDF_FONT_BOLD}`, fontStyle:'bold'}
  ]
});
// Create styles
const styles = StyleSheet.create({
  page: {
    borderTop: 15,
    borderBottom: 30,
    borderLeft: 30,
    borderRight: 30,
    paddingBottom: 95
  }, 
  pageTop: {
    backgroundColor : '#198751',
    height: 15,
    position: 'absolute',
    width: '100%',
    top: -15,
    left: 0,
    right: 0,
  },
  companyName: {
    fontSize: 9,
    marginTop: 12,
    marginBottom:70,
    flexWrap: 'wrap',
    //fontFamily: '"Poppins", sans-serif',
    fontFamily: 'Rubik',
    fontStyle: 'bold',
    color: '#000000'
  },
  section: {
    margin: '30'
  },
  containerWrapper: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    paddingBottom: 10,
    minHeight:85
  },
  image: {
    marginBottom: 10,
    width: 190,
    marginLeft: 70
  },
  leftColumn: {
    flexDirection: 'column',
    width: 280,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  heading: {
    fontSize: 18,
    marginTop: 10,
    marginBottom:10,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    color: '#000000',
    hyphens: 'none'
  },
  heading2: {
    fontSize: 16,
    margingTop: 40,
    marginBottom:5,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    color: '#000000',
    hyphens: 'none'
  },
  heading2Wrapper: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
  },
  introText: {
    fontSize:10,
    marginBottom: 5,
    color: '#000000',
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    hyphens: 'none'
  },
  introText2: {
    fontSize:10,
    marginBottom: 5,
    color: '#000000',
    fontStyle: 'regular',
    fontFamily: 'Rubik',
    hyphens: 'none'
  },
  resultsWrapper: {
    marginTop:1,
  },
  section: {
    fontSize:12,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    marginBottom:8,
    color: '#000000'
  },
  sectionWrapper: {
    marginTop: 20,
    marginBottom: 8
  },
  question: {
    fontSize:10,
    marginBottom:6,
    justifyContent: 'space-around'
  },
  questionWrapper: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    paddingTop: 10,
    paddingBottom: 6,
    borderBottom: 0.25,
    borderBottomColor: '#bcbcbe',
    borderBottomStyle: 'solid',
  },
  leftText: {
    flexDirection: 'column',
    width: 373,
    fontStyle: 'regular',
    fontFamily: 'Rubik',
    fontSize:9,
    color: '#000000'
  },
  rightText: {
    flexDirection: 'column',
    width: 162,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontSize:9,
    fontWeight: 'bold',
    color: '#000000',
    textAlign: 'right'
  },
  additionalText: {
    marginTop: 12,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontSize:9,
    fontWeight: 'bold',
    color: '#000000'
  },
  additionalAnswer: {
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontSize:9,
    fontWeight: 'bold',
    color: '#000000'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: -15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  t1: {
    color: '#147b5d',
    backgroundColor: '#147b5d',
    height: 5,
    marginBottom: 25
  },
  t2: {
    color: '#EE8E30',
    backgroundColor: '#EE8E30',
    height: 5,
    marginBottom: 25
  },
  t3: {
    color: '#C50F3C',
    backgroundColor: '#C50F3C',
    height: 5,
    marginBottom: 25
  },
  scoreRow: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    paddingTop: 4
  },
  categoryItemPsychological: {
    flexDirection: 'column',    
    backgroundColor: '#c5def7',
    padding: 5,
    margin: 5,
    width: 250,
    borderRadius: 10    
  },
  categoryTextPsychological: {
    flexDirection: 'column',
    width: 150,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize:13,
    color: "#2f5a83",
    paddingTop: 15,
    paddingLeft:15
  },
  categoryScorePsychological: {
    flexDirection: 'column',
    width: 50,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 900,
    fontSize:18,
    color: "#2f5a83",
    paddingTop: 10,
    paddingLeft:0
  },
  categoryItemSocial: {
    flexDirection: 'column',    
    backgroundColor: '#f4cacb',
    padding: 5,
    margin: 5,
    width: 250,
    borderRadius: 10    
  },
  categoryTextSocial: {
    flexDirection: 'column',
    width: 150,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize:13,
    color: "#8f2c2c",
    paddingTop: 15,
    paddingLeft:15
  },
  categoryScoreSocial: {
    flexDirection: 'column',
    width: 50,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 900,
    fontSize:18,
    color: "#8f2c2c",
    paddingTop: 10,
    paddingLeft:0
  },
  categoryItemHealth: {
    flexDirection: 'column',    
    backgroundColor: '#c7e3b3',
    padding: 5,
    margin: 5,
    width: 250,
    borderRadius: 10    
  },
  categoryTextHealth: {
    flexDirection: 'column',
    width: 150,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize:13,
    color: "#2a4f12",
    paddingTop: 12,
    paddingLeft:15
  },
  categoryScoreHealth: {
    flexDirection: 'column',
    width: 50,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 900,
    fontSize:18,
    color: "#2a4f12",
    paddingTop: 8,
    paddingLeft:0
  },
  categoryItemFunctional: {
    flexDirection: 'column',    
    backgroundColor: '#fedcbf',
    padding: 5,
    margin: 5,
    width: 250,
    borderRadius: 10    
  },
  categoryTextFunctional: {
    flexDirection: 'column',
    width: 150,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize:13,
    color: "#8c450a",
    paddingTop: 12,
    paddingLeft:15
  },
  categoryScoreFunctional: {
    flexDirection: 'column',
    width: 50,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 900,
    fontSize:18,
    color: "#8c450a",
    paddingTop: 8,
    paddingLeft:0
  },
  categoryItemMedication: {
    flexDirection: 'column',    
    backgroundColor: '#f8d5f6',
    padding: 5,
    margin: 5,
    width: 250,
    borderRadius: 10    
  },
  categoryTextMedication: {
    flexDirection: 'column',
    width: 150,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize:13,
    color: "#701d6b",
    paddingTop: 14,
    paddingLeft:15
  },
  categoryScoreMedication: {
    flexDirection: 'column',
    width: 50,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 900,
    fontSize:18,
    color: "#701d6b",
    paddingTop: 12,
    paddingLeft:0
  },
  categoryItemOverall: {
    flexDirection: 'column',    
    backgroundColor: '#f0f0f0',
    padding: 5,
    margin: 5,
    width: 250,
    borderRadius: 10    
  },
  categoryTextOverall: {
    flexDirection: 'column',
    width: 174,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize:13,
    color: "#000000",
    paddingTop: 14,
    paddingLeft:15
  },
  categoryScoreOverall: {
    flexDirection: 'column',
    width: 50,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontWeight: 900,
    fontSize:25,
    color: "#000000",
    paddingTop: 9,
    paddingLeft:0
  },
  scoreImage: {    
    flexDirection: 'column',
    width: "35px",
    margin: 5,
    height: "35px"
  }
});

const ResultsDoc = ({ pdfData, results, additionalComments }) => {

  const { resultsState } = results;
  const { pdfMeta } = pdfData;
  const { companyName, heading, text, subHeading, subTexts, additionalCommentsEmpty, page } = pdfMeta || {};
  const { additionalCommentsState } = additionalComments;
  const additionalCommentsToPrint = (additionalCommentsState?.object?.answer?.text) ? additionalCommentsState?.object?.answer?.text: additionalCommentsEmpty;
  const groupedStatus = {
     GROUPED_NONE: 0,
     GROUPED_NEW: 1,
     GROUPED_CONTINUED: 2
  }
  let grouped = groupedStatus.GROUPED_NONE;
  let mainQuestionNumber = 0;

  var totalSurveyScore = 0;
  var totalQuestions = 0;
  var totalSurveyAverage = 0;
  var categoriesWithScores = [];
  var categoriesWithScoresAverages = [];
  let medicationQuestionsCount = false;
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hours = newDate.getHours();
  let mins = newDate.getMinutes();
  let separator = "-";
  let dateToDisplay = `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}  ${hours}:${mins}`;
  let iterationCount = 0;
    return (
        <Document>
          <Page size="A4" wrap style={styles.page}>
            <View fixed>
              <View style={styles.pageTop}>
                  <Text>&nbsp;</Text>
              </View>
              <Text style={styles.companyName}>{ companyName } - {window.location.hostname}</Text>
            </View>
            <View style={ styles.section }>
              <View style={styles.containerWrapper}>
                  <View style={styles.leftColumn}>
                      <Text style={styles.heading}>{ heading }</Text>
                      <Text style={ styles.introText}>{ text }</Text>
                      {(subTexts || []).map((subText, subTextIndex) => {
                        return (
                          <View key={subTextIndex}>
                            <Text style={ styles.introText2 } >{ subText }</Text>
                          </View>
                      ) 
                      })}
                  </View>
                  <View style={styles.leftColumn}>
                      <Image style={ styles.image } src={pdfImage} />
                  </View>
              </View>
            </View>
            <View style={ styles.section }>              
              {/* Method 2 - START - for Results based on scoring in categories */}
              {
                    Q_CATEGORIES.map((category, categoryIndex) => {
                        categoriesWithScores.push({id:category, scores:""});
                        return (
                          <View key={categoryIndex}></View>
                        )
                    })
                }
              <View> 
              {(resultsState || []).map((section, sectionIndex) => {                
                iterationCount ++;
                /* Score Card Display */
                return (                  
                  iterationCount === 1 && <View key={sectionIndex}>
                    <View>
                      <Text style={styles.heading}>{section.name}</Text>
                      <Text style={styles.introText2}>{dateToDisplay}</Text>
                      {/* populate scores for the categories for each question */}
                      {
                        section.questions.map((quest, questIndex) => {

                          const answer = quest.answer.find(x =>x.answered === 'true');
                          
                          categoriesWithScores[quest.properties[0].category].scores = categoriesWithScores[quest.properties[0].category].scores === "" ? answer.score.toLowerCase() : categoriesWithScores[quest.properties[0].category].scores + "," + answer.score.toLowerCase();
                          totalSurveyScore = totalSurveyScore === "0" ? answer.score.toLowerCase() : parseInt(totalSurveyScore) + parseInt(answer.score.toLowerCase())
                          if (answer.score > 0) totalQuestions++;
                          return(
                            <View key={questIndex}>
                                {/*Display Question's category number > score of each question*/}
                                {/*<Text>{quest.properties[0].category}:{answer.score}</Text>*/}  
                            </View>  
                          )
                        })
                      }
                      {/* Test display Populated Categories */}
                      {categoriesWithScores.map((categoryScore,categoryScoreIndex) => {
                        return (
                          <View key={categoryScoreIndex}>                            
                              {/*Display Category numbers > Scores*/}
                              {/*{categoryScore.id}:{categoryScore.scores}*/} 
                          </View>
                          )
                        })
                      }
                      {/* Average Scores Calculations */}                                    
                      <View>
                          {categoriesWithScores.map((categoryScore,categoryScoreIndex) => {
                            var totalScore = 0;
                            var individualScores = categoriesWithScores[categoryScoreIndex].scores.split(",");
                            var averageScore = 0;
                            for (var i = 0; i < individualScores.length; i++) {
                                totalScore += parseFloat(individualScores[i]);
                            }
                            averageScore = totalScore/individualScores.length;
                            
                            totalSurveyAverage = totalSurveyScore/totalQuestions;
  
                            categoriesWithScoresAverages.push({id:categoryScore.id, averageScore:`${parseFloat(averageScore).toFixed(2)}`});
                            
                            return (
                                categoryScoreIndex !== 0 &&                                                
                                <View key={categoryScoreIndex}>
                                    {/*<Text>{categoryScore.id}:{categoryScore.scores}:{totalScore}:{parseFloat(averageScore).toFixed(2)}</Text>*/}
                                </View>                                                
                                )
                            })
                          }
                          {/* Display average scores - skipping first dummy category */}
                          <View style={styles.scoreRow}>
                          {  
                          categoriesWithScores[1] && 
                            <View style={styles.categoryItemPsychological}>
                              <View style={styles.scoreRow}>
                                <Image style={ styles.scoreImage } src={scoreImage1} />
                                <Text style={styles.categoryTextPsychological}>{categoriesWithScores[1].id}</Text>
                                <Text style={styles.categoryScorePsychological}>{categoriesWithScoresAverages[1].averageScore}</Text>
                              </View>
                            </View>
                          }
                          {  
                          categoriesWithScores[2] && 
                            <View style={styles.categoryItemSocial}>
                              <View style={styles.scoreRow}>
                                <Image style={ styles.scoreImage } src={scoreImage2} />
                                <Text style={styles.categoryTextSocial}>{categoriesWithScores[2].id}</Text>
                                <Text style={styles.categoryScoreSocial}>{categoriesWithScoresAverages[2].averageScore}</Text>
                              </View>
                            </View>
                          }
                          </View>
                          <View style={styles.scoreRow}>
                          {  
                          categoriesWithScores[3] && 
                            <View style={styles.categoryItemHealth}>
                              <View style={styles.scoreRow}>
                                <Image style={ styles.scoreImage } src={scoreImage3} />
                                <Text style={styles.categoryTextHealth}>{categoriesWithScores[3].id}</Text>
                                <Text style={styles.categoryScoreHealth}>{categoriesWithScoresAverages[3].averageScore}</Text>
                              </View>
                            </View>
                          }
                          {  
                          categoriesWithScores[4] && 
                            <View style={styles.categoryItemFunctional}>
                              <View style={styles.scoreRow}>
                                <Image style={ styles.scoreImage } src={scoreImage4} />
                                <Text style={styles.categoryTextFunctional}>{categoriesWithScores[4].id}</Text>
                                <Text style={styles.categoryScoreFunctional}>{categoriesWithScoresAverages[4].averageScore}</Text>
                              </View>
                            </View>  
                          }
                          </View>
                          {parseFloat(totalSurveyAverage).toFixed(2) > 0 && <View style={styles.scoreRow}>                          
                          {/* Display average scores - skipping first dummy category */}  
                            <View style={styles.categoryItemOverall}>
                              <View style={styles.scoreRow}>
                                <Text style={styles.categoryTextOverall}>Overall Score</Text>
                                <Text style={styles.categoryScoreOverall}>{parseFloat(totalSurveyAverage).toFixed(2)}</Text>
                                {/*<Text>Survey Total Score: {totalSurveyScore} / Survey Total Questions{totalQuestions}</Text>*/}
                              </View>
                            </View>                          
                          </View>
                          }
                      </View>
                    </View>
                    <View style={styles.resultsWrapper}>
                    {(resultsState || []).map((section, sectionIndex) => {
                        /* Medication Questions count */
                        medicationQuestionsCount = false;
                        section.questions.map((quest, questIndex) => {
                          let answer = quest.answer.find(x =>x.answered === 'true');
                            return(
                                answer.text.length > 0 && quest.properties[0].category === "5" && <View key={questIndex}>
                                    {medicationQuestionsCount = true}
                                </View>
                            )
                        })
                        return (
                            medicationQuestionsCount && <View key={sectionIndex} style={styles.sectionWrapper}>
                              <Text style={ styles.section }>Medication</Text>
                              <View>
                                  {
                                  section.questions.map((quest, questIndex) => {
                                      const data = quest.answer.find(x =>x.answered === 'true');
                                      const message = data && (data.score === 'T1' || data.score === 'T2' || data.score === 'T3')? "-" + data.scoreMessage: "";
                                      let questionCountDigit =  ". ";
    
                                      if (questIndex === 0) //New Section's questions
                                        mainQuestionNumber = 0; //Reset Main Question Number
    
                                      grouped = groupedStatus.GROUPED_NONE; //Default state of grouped questions 
    
                                      if (quest.id === Q_MCQ && quest.properties[0].grouped === 'true') {
                                        if (quest.properties[0].groupQuestion) 
                                          grouped = groupedStatus.GROUPED_NEW; //Brand new grouped question
                                        else   
                                          grouped = groupedStatus.GROUPED_CONTINUED; //Continued grouped question without Grouped Question Text
                                      }
    
                                      if (grouped === groupedStatus.GROUPED_NONE || grouped === groupedStatus.GROUPED_NEW)
                                        mainQuestionNumber++;
    
                                      if (grouped === groupedStatus.GROUPED_CONTINUED) {
                                        questionCountDigit = "";
                                      } 
                                      else 
                                        questionCountDigit = mainQuestionNumber + questionCountDigit;
                                        
    
                                      const answerYesNoWithText = (quest.id === Q_YES_NO_WITH_TEXT && quest.answer[0].answered === 'true')? quest.subquestion.answer[0].text : "";
                                      const answerYesNoWithTextMultiple = (quest.id === Q_YES_NO_WITH_MULTIPLE_TEXT && quest.answer[0].answered === 'true')? quest.subquestion[0].answer: "";
                                      
                                      return (
                                        
                                        quest.properties[0].category === "5" &&

                                        <View key={questIndex} style = {styles.questionWrapper}>
                                          <View style={ styles.leftText} >
                                            {
                                              ((grouped === groupedStatus.GROUPED_NEW) && <Text>{quest.properties[0].groupQuestion}</Text>)
                                            }
                                            <Text>{(grouped === groupedStatus.GROUPED_NEW)? "\n\r": ""}{quest.question}</Text>
                                            {
                                              ((answerYesNoWithText !== "") && <Text style={ styles.additionalText}>{answerYesNoWithText}</Text>)
                                            }
                                            {(answerYesNoWithTextMultiple !== "") && (answerYesNoWithTextMultiple).map((ans, ansIndex) => {
                                              return(
                                                <Text style={styles.additionalAnswer} key={ansIndex}>{(ansIndex>0)? "- " + ans.text:" "}</Text>
                                                )
                                              })
                                            }
                                          </View>
                                          <View style={ styles.rightText} >
                                            <Text>{data.text}</Text>
                                          </View>  
                                        </View>
                                      )
                                  })
                                  } 
                              </View>
                            </View>  
                        ) 
                        })}
                    </View>
                </View>
                ) 
              })}
              </View>
              <View style={styles.heading2Wrapper} break>
                <Text style={ styles.heading2 }>{ subHeading }</Text>
              </View>
              <View style={styles.resultsWrapper}>
                {(resultsState || []).map((section, sectionIndex) => {
                    return (
                        <View key={sectionIndex} style={styles.sectionWrapper}>
                          <Text style={ styles.section }>{section.name.length === 0? "Medication" : section.name}</Text>
                          <View>
                              {
                              section.questions.map((quest, questIndex) => {
                                  const data = quest.answer.find(x =>x.answered === 'true');
                                  const message = data && (data.score === 'T1' || data.score === 'T2' || data.score === 'T3')? "-" + data.scoreMessage: "";
                                  let questionCountDigit =  ". ";

                                  if (questIndex === 0) //New Section's questions
                                    mainQuestionNumber = 0; //Reset Main Question Number

                                  grouped = groupedStatus.GROUPED_NONE; //Default state of grouped questions 

                                  if (quest.id === Q_MCQ && quest.properties[0].grouped === 'true') {
                                    if (quest.properties[0].groupQuestion) 
                                      grouped = groupedStatus.GROUPED_NEW; //Brand new grouped question
                                    else   
                                      grouped = groupedStatus.GROUPED_CONTINUED; //Continued grouped question without Grouped Question Text
                                  }

                                  if (grouped === groupedStatus.GROUPED_NONE || grouped === groupedStatus.GROUPED_NEW)
                                    mainQuestionNumber++;

                                  if (grouped === groupedStatus.GROUPED_CONTINUED) {
                                    questionCountDigit = "";
                                  } 
                                  else 
                                    questionCountDigit = mainQuestionNumber + questionCountDigit;
                                    

                                  const answerYesNoWithText = (quest.id === Q_YES_NO_WITH_TEXT && quest.answer[0].answered === 'true')? quest.subquestion.answer[0].text : "";
                                  const answerYesNoWithTextMultiple = (quest.id === Q_YES_NO_WITH_MULTIPLE_TEXT && quest.answer[0].answered === 'true')? quest.subquestion[0].answer: "";
                                  
                                  return (
                                  <View key={questIndex} style = {styles.questionWrapper}>
                                    <View style={ styles.leftText} >
                                      {
                                        ((grouped === groupedStatus.GROUPED_NEW) && <Text>{questionCountDigit}{quest.properties[0].groupQuestion}</Text>)
                                      }
                                      <Text>{(grouped === groupedStatus.GROUPED_NEW)? "\n\r": questionCountDigit}{quest.question}</Text>
                                      {
                                        ((answerYesNoWithText !== "") && <Text style={ styles.additionalText}>{answerYesNoWithText}</Text>)
                                      }
                                      {(answerYesNoWithTextMultiple !== "") && (answerYesNoWithTextMultiple).map((ans, ansIndex) => {
                                        return(
                                          <Text style={styles.additionalAnswer} key={ansIndex}>{(ansIndex>0)? "- " + ans.text:" "}</Text>
                                          )
                                        })
                                      }
                                    </View>
                                    <View style={ styles.rightText} >
                                      <Text>{data.text}</Text>
                                    </View>  
                                  </View>
                                  )
                              })
                              } 
                          </View>
                        </View>  
                    ) 
                    })}
                    {
                      additionalCommentsState &&
                      <View key={'additional-comments'} style={styles.sectionWrapper}>
                            <Text style={ styles.section }>{additionalCommentsState?.heading}</Text>
                            <View key={'additional-comments'}>
                              {  
                                <View key={'additional-comments-section'} style = {styles.questionWrapper}>
                                  <View style={ styles.leftText} >
                                    <Text style={styles.additionalAnswer} key={'comments-text'}>{additionalCommentsToPrint}</Text>
                                  </View>
                                </View>
                              } 
                            </View>
                      </View> 
                    }
              </View>
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `${page} ${pageNumber} / ${totalPages}`
            )} fixed />
          </Page>
        </Document>
    );
}

export default ResultsDoc;